<template>
    <html>
      <body class="" style="background: #FFF">
          <HeaderMobil />
          <MenuLateralMobile/>
          <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col" style="height:auto;">
            <MenuLateral/>
            <div class="row-span-2 col-span-6" style="background:#FFF;padding:20px;">
              <ComprobanteAdjuntadoExitosamente/>
            </div>
            <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
            <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
          </div>
      </body>
    </html>
</template>


<script>

import ComprobanteAdjuntadoExitosamente from '@/components/Cuenta/PagoParteSocial/ComprobanteAdjuntadoExitosamente.vue'
import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue"
import Footer from '@/components/Footer.vue'
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue"
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/Cuenta/MenuLateral.vue'
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
  data() {
    return {

    }
  },
  name: 'ComprobanteAdjuntadoExitosamenteView',
  components: {
    ComprobanteAdjuntadoExitosamente,
    HeaderMobil,
    MenuLateralMobile,
    MenuLateral,
    MenuPrincipal,
    Footer
  },
  beforeMount() {
    this.checkLogin();
  },
  methods:{
    checkLogin(){
      var user = getTokenDecoden();
      if(user != null){
        if(user.obj['role'] == "administrator"){
          // window.location.href = "/admin/principal";
           this.$router.push({path:"/admin/principal"});
        } else if(user.obj['role'] == "loan_analyst"){
          // window.location.href = "/analyst/principal";
           this.$router.push({path:"/analyst/principal"});
        }
      }
      else{
        // window.location.href = "/"
        this.$router.push({path:"/"});
      }
    }
  }
}
</script>