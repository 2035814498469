<template>
  <div>
    <ProcesoExitoso title="El comprobante se ha adjuntado correctamente" description="El comprobante estará en proceso de revisión" />
  </div>
</template>

<script>
import ProcesoExitoso from '@/components/Cuenta/PagoParteSocial/ProcesoExitoso.vue'

export default {
  data() {
    return {}
  },
  name: 'ComprobanteAdjuntadoExitosamenteComponent',
  components: {
    ProcesoExitoso
  }
}
</script>